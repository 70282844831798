import { useMemo, useState } from "react"

import {
  MultiPillSelect,
  MultiPillSelectResult,
} from "src/components/Paradise/MultiPillSelect"

// ts-prune-ignore-next
export function ParadiseInternalSearchMultiPillSelect({
  options,
  value,
  onSelected,
}: {
  options: MultiPillSelectResult[]
  value: MultiPillSelectResult[]
  onSelected: (selected: MultiPillSelectResult[]) => void
}) {
  const [searchInput, setSearchInput] = useState("")

  const internalOptions = useMemo(() => {
    if (options) {
      return options.filter((o) =>
        o.name.toLowerCase().includes(searchInput.toLowerCase())
      )
    }

    return options ?? []
  }, [options, searchInput])

  return (
    <MultiPillSelect
      options={internalOptions}
      value={value}
      onSelected={onSelected}
      onSearch={(input) => {
        setSearchInput(input)
      }}
      onCreate={(i) => onSelected([...value, { id: i, name: i }])}
      loading={false}
      debounceTime={0}
    />
  )
}
