import { useState } from "react"

import { MultiPillSelectResult } from "src/components/Paradise/MultiPillSelect"
import { ParadiseInternalSearchMultiPillSelect } from "src/components/Paradise/ParadiseInternalSearchMultiPillSelect"
import {
  SettingContainer,
  TSettingContainerOnSaveReturnType,
} from "src/components/Settings/Setting/SettingContainer"
import { spacing } from "src/ui/spacing"

export function ParadiseSettingMultiPillSelect({
  title,
  initialSelected,
  displayValue,
  options,
  onSave,
  disabled,
}: {
  title: React.ReactNode
  displayValue: React.ReactNode
  initialSelected: MultiPillSelectResult[]
  options: MultiPillSelectResult[]
  onSave: (v: MultiPillSelectResult[]) => TSettingContainerOnSaveReturnType
  disabled?: boolean
}) {
  const [saveValue, setSaveValue] =
    useState<MultiPillSelectResult[]>(initialSelected)

  return (
    <SettingContainer
      title={title}
      displayValue={displayValue}
      preventSubmitOnEnter
      gap={spacing.XS2}
      titleProps={{
        type: "nano",
        color: "secondary",
      }}
      InputComponent={() => (
        <Input
          key={JSON.stringify(initialSelected)}
          onSelect={(selected) => {
            setSaveValue(selected)
          }}
          initialSelected={saveValue}
          options={options}
        />
      )}
      onSave={() => {
        return onSave(saveValue)
      }}
      onClose={() => {
        setSaveValue(initialSelected)
      }}
      disabled={disabled}
    />
  )
}

function Input({
  initialSelected,
  options,
  onSelect,
}: {
  initialSelected: MultiPillSelectResult[]
  options: MultiPillSelectResult[]
  onSelect: (v: MultiPillSelectResult[]) => void
}) {
  const [value, setValue] = useState<MultiPillSelectResult[]>(initialSelected)

  return (
    <ParadiseInternalSearchMultiPillSelect
      value={value}
      options={options}
      onSelected={(v) => {
        setValue(v)
        onSelect(v)
      }}
    />
  )
}
